<template>
  <div class="service">
<!--    <div class="service-bg"></div>-->
    <div class="service-top"  id="service" ref="wrapper" >
      <van-pull-refresh v-model="isLoading" pulling-text="下拉查看历史消息" loosing-text="释放即可查看历史消息" @refresh="onRefresh">
      <div v-for="(item,index) in record">
       <div v-if="item.sender == 'foryor'">
        <div  class="box" v-if="item.messageType =='01' || item.messageType =='03' ">
          <div class="wenti-img"><img src="../../assets/images/xjqr.png"></div>
          <div class="wenti-bota">
              <span>
                <div v-html="item.content"></div>
                <div>

                </div>
                <div class="jiejue" v-if="item.solved == null ">
                   <div @click="onSolve(item,index,false)">
                    <img src="../../assets/images/wjj.png">
                    未解决
                  </div>
                     <div @click="onSolve(item,index,true)">
                    <img  src="../../assets/images/yjj.png">
                    已解决
                  </div>


                </div>
                <div  v-else>
                  <div class="jiejue"  v-if="item.solved">
                     <div @click="onSolve(item,index,false)">
                        <img src="../../assets/images/wjj.png">
                        未解决
                      </div>
                       <div @click="onSolve(item,index,true)">
                        <img  src="../../assets/images/yjjh.png">
                        已解决
                      </div>

                  </div>
                  <div class="jiejue" v-else>
                      <div @click="onSolve(item,index,false)">
                        <img src="../../assets/images/wjjh.png">
                        未解决
                      </div>
                      <div @click="onSolve(item,index,true)">
                        <img  src="../../assets/images/yjj.png">
                        已解决
                      </div>

                  </div>
                </div>
              </span>

          </div>

        </div>
        <div  class="box" v-if="item.messageType == '04'">
          <div class="wenti-img"><img src="../../assets/images/xjqr.png"></div>
          <div class="wenti-tab">
            <span> 猜您要问</span>
            <div style="display: flex">
              <div class="tab-left">
                <div v-for="item in initList.QACategory" @click="tabBtn(item.name,item.code)" :class="{'active':isActive == item.code}">
                  {{item.name}}
                  <span class="line" v-if="isActive == item.code"></span>
                </div>
              </div>
              <div class="tab-right">
                <div class="tab-div"  v-for="item in problemListTab" @click="problemBtn(item)">{{item}}</div>
                <div class="refresh" @click="tabgetProblem" ><img src="../../assets/images/hyh.png">换一换</div>
              </div>
            </div>

          </div>
        </div>
        <div  class="box"  v-if="item.messageType =='05'">
          <div class="wenti-img"><img src="../../assets/images/xjqr.png"></div>
          <problem05 :content="item.content" @getProblemval="getProblemval"></problem05>
<!--          <div class="wenti-taba">-->
<!--              <div class="tab-div"  v-for="it in item.content" @click="problemBtn(it)">{{it}}</div>-->
<!--          </div>-->
        </div>
        <div  class="box"  v-if="item.messageType =='06'">
            <div class="wenti-img"><img src="../../assets/images/xjqr.png"></div>
          <problem :code="item.content" @getScrollTop="getScrollTop" @getProblemval="getProblemval"></problem>
        </div>
        <div  class="box" style="width: 94%"  v-if="item.messageType =='07'" >
          <div class="wenti-top">
            <div class="wenti-img"><img src="../../assets/images/xjqr.png"></div>
            <span class="wenti-tips">
              {{item.content}}
            </span>
          </div>
          <div class="wenti-bot">
            <div class="title">若您符合以下投保条件可进一步了解本产品</div>
            <div class="wenti-sm">
                (1)18-70周岁、 已确诊乳腺癌且接受乳腺癌手术治疗的女性患者;<br />
                (2)切缘阴性; (3) 没有远处转移;<br />
                (4)病理分期为原位癌(即0期)，或|期(包括IA和IB)或II期(包括IIA和IIB) ; <br />
                (5)术后没有复发史;<br />
                (6)未发生条款约定的重大疾病。
            </div>
          </div>
        </div>
       </div>
        
        <div class="box-right" v-else>
        <div class="wenti">
          <span>{{item.content}}</span>
        </div>
     </div>
     </div>
      </van-pull-refresh>
   </div>
   <div class="kong"></div>
   <div class="service-bottom">
     <div class="bottom-list">
       <div v-for="item in initList.QACategory" @click="typeBtn(item.name,item.code)">{{item.name}}</div>
     </div>
     <div class="send-box" id="send-box">
       <van-cell-group>
        <van-field v-model="value"  @keyup.enter.native="onSend" placeholder="请一句话描述您的问题…" />
      </van-cell-group>
      <div @click="onSend"><img src="../../assets/images/fs.png"></div>
     </div>
   </div>
    <div class="robot" @click="jump">
      <div class="robot-title"><p>智能核保</p></div>
      <div class="robot-img"><img src="../../assets/images/robot.png"> </div>
    </div>
  </div>
</template>

<script>
 import { newSession,sessionList,questionCate,enumList,getCategory,getSolve} from '@/api'
 import { setCookie } from '@/util/util'
import Vue from 'vue'
import { Form ,Field,Button,DatetimePicker,Popup,RadioGroup, Radio,Toast,Loading,PullRefresh} from 'vant';
Vue.use(Form);
Vue.use(Toast);
Vue.use(Loading)
Vue.use(PullRefresh)
//import BScroll from '@better-scroll/core'
 import problem from "@/views/service/problem";
 import problem05 from "@/views/service/problem05";
export default {
  name: 'service',
  data() {
    return {
      value:"",
      websock: null,
      session:"",
      //path:"ws://192.168.1.234:18091/chat",
      //path:"ws://47.93.254.18:7500/chat",
      path:'ws://agency-test.foryor.com/chat',
      socket:"",
      messageType:"01",
      record:[],
      initList:[],
      problemCode:"",
      problemList:{},
      isActive:"",
      problemListTab:[],
      isLoading:false,
      pageSize:10,
      currentPage:1,
      maxChatLogId:"",
      page:1,
      pageNo:1,
      rollHeight:"",
      solve1:false,
      solve2:true,
      sendHeight:0,
      vdaH:0
    }
    // path:"ws://47.93.254.18:7500/chat",
  },
   components: {
     problem,
     problem05
   },
  created() {
    // let h = document.documentElement.clientHeight || document.body.clientHeight;
    // this.vdaH = h -$('.service-bottom').height() + 'px';
   // alert(process.env.VUE_APP_API)
   //  var domain = process.env.VUE_APP_API.match(/[a-zA-Z]{0,62}(\.[a-zA-Z][a-zA-Z]{0,62})+\.?/);
   // // console.log(domain)
   //  this.path = 'ws://'+domain[0]+'/chat'
  //  this.path = 'ws://agency-test.foryor.com/chat'
    //this.getInit()
    if(this.$store.getters.init){
      this.initList =this.$store.getters.init
      this.isActive  =  this.initList.QACategory[0].code
      this.tabgetProblem()
    }else {
      this.getInit()
    }
   //this.getnewSession()

     if(this.$store.getters.session){
       this.session = this.$store.getters.session
       this.getsessionList()
       this.init();
     }else{
       console.log('1')
        this.getnewSession()
     }

  },
   mounted() {

  },
  methods: {
    getInit(){
      enumList().then((res) => {
        let obj = {};
        res.map((e) => {
          obj[e.enumType] = e.enumList;
        });
        this.initList = obj
        this.isActive  =  obj.QACategory[0].code
        this.tabgetProblem()
        this.$store.commit('SET_INIT', obj)
        setCookie('init',obj)
      })
    },
      //获取session数据
      getnewSession(){
        newSession().then((res) => {
          this.session = res
          this.init();
          this.getsessionList()
          this.$store.commit('SET_SESSION', res)
          setCookie('session',res)
        })
      },
      //初始化
      init() {
        console.log('chonglian')
        if(typeof(WebSocket) === "undefined"){
            alert("您的浏览器不支持socket")
        }else{
            // 实例化socket
            // var vm =this;
            // var token = vm.session
            this.socket = new WebSocket(this.path,this.session);
            // 监听socket连接
            this.socket.onopen = this.open
            // 监听socket错误信息
            this.socket.onerror = this.error
            // 监听socket消息
            this.socket.onmessage = this.getMessage
        }
      },
      open() {
        console.log("socket连接成功")
        let send = sessionStorage.getItem('send')
        console.log(send)
        if(send){
          this.send();
        }
      },
      error() {
        this.init()
       // Toast('连接错误,请推出页面重新进入')
          console.log("连接错误")
      },
      getMessage(msg) {
        //接收
       // console.log(msg)
        let all = JSON.parse(msg.data)
        console.log(all)
        this.record.push({'sender':'foryor','content':all.content,'id':all.responseMessageId,'messageType':all.responseMessageType})
        //滚动到页面最底部
        this.$nextTick(() => {
          var p = document.getElementById('service');
         // console.log(this.sendHeight)
          p.scrollTop = this.sendHeight-100;
          this.rollHeight = p.scrollHeight
          //console.log('聊天时高度：'+this.rollHeight)
        })
      },
      typeBtn(val,code){
        this.value =val;
        this.problemCode = code
        this.messageType = '08'
        //获取问题
        this.getProblem(code)
        this.send();
      },
    onSend(){
      this.messageType = '01'
      this.send();
    },
      problemBtn(val){
        this.value =val;
        this.problemCode = val
        this.messageType = '02'
        this.send();
      },
      send () {
        if( this.socket.readyState !=  this.socket.OPEN){
          sessionStorage.setItem('send','on');
          this.init()
        }else{
          this.record.push({'sender':'','content':this.value})
          sessionStorage.removeItem('send')
          let box={
            "messageType":this.messageType,
            "content":this.value,
          }
          this.socket.send(JSON.stringify(box))
          this.value =''
          this.$nextTick(() => {
            var p = document.getElementById('service');
            this.sendHeight= p.scrollHeight;
            //   console.log('fa:'+this.sendHeight)
            //  this.rollHeight = p.scrollHeight
            //console.log('聊天时高度：'+this.rollHeight)
          })
        }
      },
      close: function () {
        this.socket.close();
        console.log("socket已经关闭")
      },
      //获取聊天记录
      getsessionList(){
        sessionList(this.session,this.maxChatLogId,this.pageSize,this.currentPage).then((res) => {
          this.record = res
          let length = res.length-1
          this.maxChatLogId = res[length].id
          //滚动到页面最底部
          this.$nextTick(() => {
            var p = document.getElementById('service');
            //console.log('qq'+p.scrollHeight)
            p.scrollTop = p.scrollHeight;
            this.rollHeight = p.scrollHeight
            this.sendHeight= p.scrollHeight+150;
           // console.log('第一次聊天记录高度：'+this.rollHeight)
          })
        })
      },
    //获取问题
    getProblem(code){
        let page = this.page++
      getCategory(code,'5',page).then((res) => {
        if(code == '10'){
          this.problemList.tbzx = res
        }
        if(code == '20'){
          this.problemList.bzfw = res
        }
        if(code == '30'){
          this.problemList.lpyw = res
        }
        if(code == '40'){
          this.problemList.bflj = res
        }
        if(code== '50'){
          this.problemList.jbzs = res
        }
      })
    },
    getScrollTop(){
      this.$nextTick(() => {
        var p = document.getElementById('service');
        p.scrollTop = p.scrollHeight;
      })
    },
    //获取问题
    getProblemval(val){
      this.problemBtn(val)
    },
    //tab
    tabBtn(name,code){
      this.isActive = code;
      this.pageNo = 1
      this.tabgetProblem()
    },
    tabgetProblem(){
      getCategory(this.isActive ,'4', this.pageNo++).then((res) => {
       this.problemListTab = res
      })
    },
    //分页
    onRefresh(){
      if(this.maxChatLogId == ''){
          Toast('当前已是全部数据～')
        this.isLoading = false;
          return
      }
      sessionList(this.session,this.maxChatLogId,this.pageSize,this.currentPage+1).then((res) => {
        this.isLoading = false;
        if(res.length!=0){
          let length = res.length-1
          this.maxChatLogId = res[length].id
          this.record = [...res,...this.record]
          this.$nextTick(() => {
            var h = document.getElementById('service');
            // console.log('当前高度：'+h.scrollHeight)
            h.scrollTop = h.scrollHeight - this.rollHeight
            // console.log('滚动高度：'+this.roll)
            // this.rollHeight= h.scrollHeight
          })

        }


      })

    },
    //是否解决问题
    onSolve(item,index,isSolved){
      let messageId = item.id
      item.solved = isSolved
      getSolve(this.session,messageId,isSolved).then((res)=>{
        this.$nextTick(() => {
          this.$set(this.record,index,item)
        })

      })
    },
    //跳转
    jump(){
      if(this.$store.getters.token){
       // this.$router.push({path:'/'});
        this.$router.push({path:'/caseList'});
      }else {
        this.$router.push({path:'/login'});
      }
    },
    changeBlur(){
      let u = navigator.userAgent, app = navigator.appVersion;
      let isIOS = !!u.match(/\(i[^;]+;( U;)? CPU.+Mac OS X/);
      if(isIOS){
        setTimeout(() => {
          const scrollHeight = document.documentElement.scrollTop || document.body.scrollTop || 0
          window.scrollTo(0, Math.max(scrollHeight - 1, 0))
        }, 200)
      }
    }

  },
   destroyed () {
        // 销毁监听
        this.socket.onclose = this.close()
    },

  updated(){

  },
}
</script>

<style lang="scss" scoped>
.service{
  width: 100%;
  height: 100%;
  background: url('../../assets/images/bg.jpg');
  background-size:100% 100% ;
  position: fixed;
  left: 0;
  top: 0;
  overflow: hidden;
  font-size: .28rem;
}
.service-bg{
  width: 100%;
  height: 100vh;
  background: url('../../assets/images/bg.jpg');
  background-size:100% 100% ;
  position: fixed;
  left: 0;
  top: 0;
  z-index: 0;

}
.service-top{
  width: 100%;
  height: 84%;
  overflow-y: scroll;
  -webkit-overflow-scrolling: touch;
}
.box{
  width: 85%;
  margin-right: 12%;
  margin-left: 3%;
}
.box-right{
   width: 85%;
  margin-left: 12%;
  margin-right: 3%;
 margin-bottom: 15px;
 overflow: hidden;

  .wenti{
   
   // float:right;
    display: flex;
    flex-direction: row-reverse;
    span{
        background: #1675FF;
        color: #fff;
        box-shadow: 0px -1px 9px 0px rgba(111, 210, 255, 0.09);
        border-radius: 8px  0px 8px 8px;
        padding: 12px;
        display: inline-block;
    }
  }
}
.wenti-img{
    width: 100%;
    height: 17px;
    img{
       width: 20px;
       height: 17px;
    }
  }
.wenti-top{
  margin-bottom: 15px;
  .wenti-img{
    width: 100%;
    height: 17px;
    img{
       width: 20px;
       height: 17px;
    }
  }
  .wenti-tips{
    padding: 12px;
    background: #fff;
    color: #1675FF;
    box-shadow: 0px -1px 9px 0px rgba(111, 210, 255, 0.09);
    border-radius: 0px 8px 8px 8px;
    display:inline-block;
  }
}
.wenti-bot{ 
    background: #fff;
    box-shadow: 0px -1px 9px 0px rgba(111, 210, 255, 0.09);
    border-radius: 0px 8px 8px 8px;
    margin-bottom: .3rem;
    padding: .3rem;
    line-height: 24px;
    .title{
      color: #1675FF;
      font-weight: 500;
      text-align: center;
      font-size: .28rem;
      padding: .1rem .4rem .4rem .4rem;
    }
}
.wenti-bota{
    span{
      background: #fff;
      box-shadow: 0px -1px 9px 0px rgba(111, 210, 255, 0.09);
      border-radius: 0px 8px 8px 8px;
      margin-bottom: .3rem;
      padding: .3rem;
      display: inline-block;
      font-size: .28rem!important;
      line-height: .56rem;
    }
    
    .title{
      color: #1675FF;
      font-weight: 500;
      text-align: center;
      font-size: .28rem;
      padding: .1rem .4rem .4rem .4rem;
    }
}
.wenti-tab{
 // display: flex;
   margin-bottom: .3rem;
  background: #FFFFFF;
  box-shadow: 0px -1px 9px 0px rgba(111, 210, 255, 0.09);
  border-radius: 8px;
  span{
    color: #1675FF;
    margin-bottom: .3rem;
    display: inline-block;
    padding-left: .2rem;
    padding-top: .3rem;
  }
  .tab-left{
    width: 18%;
    div{
      width: 100%;
      text-align: left;
      padding-bottom: .4rem;
      margin-left: .2rem;
    }
    .active{
      color: #1675FF;
    }
    .line{
      width: 10px;
      height: 2px;
      background: #1675FF;
      display: block;
      margin: 0 auto;
      padding: 0;
    }
  }
  .tab-right{
    padding: 0 .3rem;

    margin-left: .3rem;
    width: 76%;
    position: relative;
    .tab-div{
      line-height: .48rem;
      max-height: 1rem;
     display: -webkit-box;
      -webkit-box-orient: vertical;
      -webkit-line-clamp: 2;
      overflow: hidden;
      margin-bottom: .15rem;
    }
    .refresh{
      width: 100%;
      line-height: .6rem;
      text-align: center;
      img{
        width: .24rem;
        height: .24rem;
        display: inline-block;
        vertical-align: middle;
        margin-right: .16rem;
      }
  }
  }
  
}

.wenti-taba{
   background: #fff;
    box-shadow: 0px -1px 9px 0px rgba(111, 210, 255, 0.09);
    border-radius: 0px 8px 8px 8px;
    margin-bottom: .3rem;
    padding: .3rem;
    line-height: .48rem;
    .refresh{
      width: 100%;
      line-height: .6rem;
      text-align: center;
      img{
        width: .24rem;
        height: .24rem;
        display: inline-block;
        vertical-align: middle;
        margin-right: .16rem;
      }
  }
}
.kong{
  width: 100%;
  height: 2.4rem;
}
.service-bottom{
  width: 100%;
  background: #fff;
  position: fixed;
  bottom: 0;
  left: 0;
  padding: .3rem;
  .bottom-list{
    display: flex;
    div{
      padding: .08rem;
      border-radius: 20px;
      border:1px solid #C4C4C4;
      font-size: .24rem;
      color: #949599;
      margin-right: .2rem;
    }
  }
}
.robot{
  position: fixed;
  right: .3rem;
  bottom: 2.8rem;
  text-align: center;
  font-weight: 500;
  color: #0068FF;
  font-size: .24rem;
  img{
    width: .68rem;
    height: .88rem;
  }
}
.send-box{
  background: #F2F3F7;
  border-radius: 17px;
  display: flex;
  overflow: hidden;
  margin-top: .24rem;
  img{
    width: .28rem;
    height: .28rem;
    float: right;
    margin-top: .25rem;
    margin-left: .1rem;
  }
}
.jiejue{
  color: #C4C4C4;
  font-size: .24rem;
  margin-top: .2rem;
  width: 100%;
  border-top: 1px solid rgba(151, 151, 151, .1);
  text-align: right;
  div{
    margin-left: .2rem;
    float: right;
  }
  img{
    width: .24rem;
    height: .24rem;
  }
}
.robot-title{
  margin-bottom: .2rem;
}
.wenti-sm{
  line-height: .56rem;
}
</style>
<style>
#send-box .van-cell-group,#send-box .van-cell{
  width: 90%;
  background: #F2F3F7!important;
}
</style>
