var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "service" }, [
    _c(
      "div",
      { ref: "wrapper", staticClass: "service-top", attrs: { id: "service" } },
      [
        _c(
          "van-pull-refresh",
          {
            attrs: {
              "pulling-text": "下拉查看历史消息",
              "loosing-text": "释放即可查看历史消息"
            },
            on: { refresh: _vm.onRefresh },
            model: {
              value: _vm.isLoading,
              callback: function($$v) {
                _vm.isLoading = $$v
              },
              expression: "isLoading"
            }
          },
          _vm._l(_vm.record, function(item, index) {
            return _c("div", [
              item.sender == "foryor"
                ? _c("div", [
                    item.messageType == "01" || item.messageType == "03"
                      ? _c("div", { staticClass: "box" }, [
                          _c("div", { staticClass: "wenti-img" }, [
                            _c("img", {
                              attrs: {
                                src: require("../../assets/images/xjqr.png")
                              }
                            })
                          ]),
                          _c("div", { staticClass: "wenti-bota" }, [
                            _c("span", [
                              _c("div", {
                                domProps: { innerHTML: _vm._s(item.content) }
                              }),
                              _c("div"),
                              item.solved == null
                                ? _c("div", { staticClass: "jiejue" }, [
                                    _c(
                                      "div",
                                      {
                                        on: {
                                          click: function($event) {
                                            return _vm.onSolve(
                                              item,
                                              index,
                                              false
                                            )
                                          }
                                        }
                                      },
                                      [
                                        _c("img", {
                                          attrs: {
                                            src: require("../../assets/images/wjj.png")
                                          }
                                        }),
                                        _vm._v(" 未解决 ")
                                      ]
                                    ),
                                    _c(
                                      "div",
                                      {
                                        on: {
                                          click: function($event) {
                                            return _vm.onSolve(
                                              item,
                                              index,
                                              true
                                            )
                                          }
                                        }
                                      },
                                      [
                                        _c("img", {
                                          attrs: {
                                            src: require("../../assets/images/yjj.png")
                                          }
                                        }),
                                        _vm._v(" 已解决 ")
                                      ]
                                    )
                                  ])
                                : _c("div", [
                                    item.solved
                                      ? _c("div", { staticClass: "jiejue" }, [
                                          _c(
                                            "div",
                                            {
                                              on: {
                                                click: function($event) {
                                                  return _vm.onSolve(
                                                    item,
                                                    index,
                                                    false
                                                  )
                                                }
                                              }
                                            },
                                            [
                                              _c("img", {
                                                attrs: {
                                                  src: require("../../assets/images/wjj.png")
                                                }
                                              }),
                                              _vm._v(" 未解决 ")
                                            ]
                                          ),
                                          _c(
                                            "div",
                                            {
                                              on: {
                                                click: function($event) {
                                                  return _vm.onSolve(
                                                    item,
                                                    index,
                                                    true
                                                  )
                                                }
                                              }
                                            },
                                            [
                                              _c("img", {
                                                attrs: {
                                                  src: require("../../assets/images/yjjh.png")
                                                }
                                              }),
                                              _vm._v(" 已解决 ")
                                            ]
                                          )
                                        ])
                                      : _c("div", { staticClass: "jiejue" }, [
                                          _c(
                                            "div",
                                            {
                                              on: {
                                                click: function($event) {
                                                  return _vm.onSolve(
                                                    item,
                                                    index,
                                                    false
                                                  )
                                                }
                                              }
                                            },
                                            [
                                              _c("img", {
                                                attrs: {
                                                  src: require("../../assets/images/wjjh.png")
                                                }
                                              }),
                                              _vm._v(" 未解决 ")
                                            ]
                                          ),
                                          _c(
                                            "div",
                                            {
                                              on: {
                                                click: function($event) {
                                                  return _vm.onSolve(
                                                    item,
                                                    index,
                                                    true
                                                  )
                                                }
                                              }
                                            },
                                            [
                                              _c("img", {
                                                attrs: {
                                                  src: require("../../assets/images/yjj.png")
                                                }
                                              }),
                                              _vm._v(" 已解决 ")
                                            ]
                                          )
                                        ])
                                  ])
                            ])
                          ])
                        ])
                      : _vm._e(),
                    item.messageType == "04"
                      ? _c("div", { staticClass: "box" }, [
                          _c("div", { staticClass: "wenti-img" }, [
                            _c("img", {
                              attrs: {
                                src: require("../../assets/images/xjqr.png")
                              }
                            })
                          ]),
                          _c("div", { staticClass: "wenti-tab" }, [
                            _c("span", [_vm._v(" 猜您要问")]),
                            _c("div", { staticStyle: { display: "flex" } }, [
                              _c(
                                "div",
                                { staticClass: "tab-left" },
                                _vm._l(_vm.initList.QACategory, function(item) {
                                  return _c(
                                    "div",
                                    {
                                      class: {
                                        active: _vm.isActive == item.code
                                      },
                                      on: {
                                        click: function($event) {
                                          return _vm.tabBtn(
                                            item.name,
                                            item.code
                                          )
                                        }
                                      }
                                    },
                                    [
                                      _vm._v(" " + _vm._s(item.name) + " "),
                                      _vm.isActive == item.code
                                        ? _c("span", { staticClass: "line" })
                                        : _vm._e()
                                    ]
                                  )
                                }),
                                0
                              ),
                              _c(
                                "div",
                                { staticClass: "tab-right" },
                                [
                                  _vm._l(_vm.problemListTab, function(item) {
                                    return _c(
                                      "div",
                                      {
                                        staticClass: "tab-div",
                                        on: {
                                          click: function($event) {
                                            return _vm.problemBtn(item)
                                          }
                                        }
                                      },
                                      [_vm._v(_vm._s(item))]
                                    )
                                  }),
                                  _c(
                                    "div",
                                    {
                                      staticClass: "refresh",
                                      on: { click: _vm.tabgetProblem }
                                    },
                                    [
                                      _c("img", {
                                        attrs: {
                                          src: require("../../assets/images/hyh.png")
                                        }
                                      }),
                                      _vm._v("换一换")
                                    ]
                                  )
                                ],
                                2
                              )
                            ])
                          ])
                        ])
                      : _vm._e(),
                    item.messageType == "05"
                      ? _c(
                          "div",
                          { staticClass: "box" },
                          [
                            _c("div", { staticClass: "wenti-img" }, [
                              _c("img", {
                                attrs: {
                                  src: require("../../assets/images/xjqr.png")
                                }
                              })
                            ]),
                            _c("problem05", {
                              attrs: { content: item.content },
                              on: { getProblemval: _vm.getProblemval }
                            })
                          ],
                          1
                        )
                      : _vm._e(),
                    item.messageType == "06"
                      ? _c(
                          "div",
                          { staticClass: "box" },
                          [
                            _c("div", { staticClass: "wenti-img" }, [
                              _c("img", {
                                attrs: {
                                  src: require("../../assets/images/xjqr.png")
                                }
                              })
                            ]),
                            _c("problem", {
                              attrs: { code: item.content },
                              on: {
                                getScrollTop: _vm.getScrollTop,
                                getProblemval: _vm.getProblemval
                              }
                            })
                          ],
                          1
                        )
                      : _vm._e(),
                    item.messageType == "07"
                      ? _c(
                          "div",
                          { staticClass: "box", staticStyle: { width: "94%" } },
                          [
                            _c("div", { staticClass: "wenti-top" }, [
                              _c("div", { staticClass: "wenti-img" }, [
                                _c("img", {
                                  attrs: {
                                    src: require("../../assets/images/xjqr.png")
                                  }
                                })
                              ]),
                              _c("span", { staticClass: "wenti-tips" }, [
                                _vm._v(" " + _vm._s(item.content) + " ")
                              ])
                            ]),
                            _c("div", { staticClass: "wenti-bot" }, [
                              _c("div", { staticClass: "title" }, [
                                _vm._v("若您符合以下投保条件可进一步了解本产品")
                              ]),
                              _c("div", { staticClass: "wenti-sm" }, [
                                _vm._v(
                                  " (1)18-70周岁、 已确诊乳腺癌且接受乳腺癌手术治疗的女性患者;"
                                ),
                                _c("br"),
                                _vm._v(" (2)切缘阴性; (3) 没有远处转移;"),
                                _c("br"),
                                _vm._v(
                                  " (4)病理分期为原位癌(即0期)，或|期(包括IA和IB)或II期(包括IIA和IIB) ; "
                                ),
                                _c("br"),
                                _vm._v(" (5)术后没有复发史;"),
                                _c("br"),
                                _vm._v(" (6)未发生条款约定的重大疾病。 ")
                              ])
                            ])
                          ]
                        )
                      : _vm._e()
                  ])
                : _c("div", { staticClass: "box-right" }, [
                    _c("div", { staticClass: "wenti" }, [
                      _c("span", [_vm._v(_vm._s(item.content))])
                    ])
                  ])
            ])
          }),
          0
        )
      ],
      1
    ),
    _c("div", { staticClass: "kong" }),
    _c("div", { staticClass: "service-bottom" }, [
      _c(
        "div",
        { staticClass: "bottom-list" },
        _vm._l(_vm.initList.QACategory, function(item) {
          return _c(
            "div",
            {
              on: {
                click: function($event) {
                  return _vm.typeBtn(item.name, item.code)
                }
              }
            },
            [_vm._v(_vm._s(item.name))]
          )
        }),
        0
      ),
      _c(
        "div",
        { staticClass: "send-box", attrs: { id: "send-box" } },
        [
          _c(
            "van-cell-group",
            [
              _c("van-field", {
                attrs: { placeholder: "请一句话描述您的问题…" },
                nativeOn: {
                  keyup: function($event) {
                    if (
                      !$event.type.indexOf("key") &&
                      _vm._k($event.keyCode, "enter", 13, $event.key, "Enter")
                    )
                      return null
                    return _vm.onSend.apply(null, arguments)
                  }
                },
                model: {
                  value: _vm.value,
                  callback: function($$v) {
                    _vm.value = $$v
                  },
                  expression: "value"
                }
              })
            ],
            1
          ),
          _c("div", { on: { click: _vm.onSend } }, [
            _c("img", { attrs: { src: require("../../assets/images/fs.png") } })
          ])
        ],
        1
      )
    ]),
    _c("div", { staticClass: "robot", on: { click: _vm.jump } }, [
      _vm._m(0),
      _vm._m(1)
    ])
  ])
}
var staticRenderFns = [
  function() {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "robot-title" }, [
      _c("p", [_vm._v("智能核保")])
    ])
  },
  function() {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "robot-img" }, [
      _c("img", { attrs: { src: require("../../assets/images/robot.png") } })
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }