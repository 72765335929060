var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", [
    _c(
      "div",
      { staticClass: "wenti-taba" },
      [
        _c("span", [_vm._v("猜您要问")]),
        _vm._l(_vm.problemList, function(item) {
          return _c(
            "div",
            {
              staticClass: "tab-div",
              on: {
                click: function($event) {
                  return _vm.problemBtn(item)
                }
              }
            },
            [
              _c("div", [_vm._v(_vm._s(item))]),
              _c(
                "div",
                { staticClass: "arrow" },
                [
                  _c("van-icon", {
                    attrs: { color: "rgba(51, 51, 51, .3)", name: "arrow" }
                  })
                ],
                1
              )
            ]
          )
        }),
        _c(
          "div",
          {
            staticClass: "refresh",
            on: {
              click: function($event) {
                return _vm.getProblem()
              }
            }
          },
          [
            _c("img", {
              attrs: { src: require("../../assets/images/hyh.png") }
            }),
            _vm._v("换一换")
          ]
        )
      ],
      2
    )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }