<template>
  <div>
       <div class="wenti-taba">
         <span>猜您要问</span>
              <div class="tab-div"  v-for="item in problemList" @click="problemBtn(item)">
                <div>{{item}}</div>
                <div class="arrow"><van-icon color="rgba(51, 51, 51, .3)" name="arrow" /></div>
              </div>
          </div>
  </div>

</template>

<script>

export default {
  name: 'service',
  data() {
    return {
      problemList:[],
      page:1
    }
  },
   components: {

   },
  props: {
    content:{
      type: String,
      default: '',
    },

  },
  created() {
  },
   mounted() {
    this.problemList =  JSON.parse(this.content)
  },
  methods: {
    //选择问题
    problemBtn(val){
      this.$emit('getProblemval',val)
    }

  },
  updated(){

  },
}
</script>

<style lang="scss" scoped>


.wenti-taba{
   background: #fff;
    box-shadow: 0px -1px 9px 0px rgba(111, 210, 255, 0.09);
    border-radius: 0px 8px 8px 8px;
    margin-bottom: .3rem;
    padding: .3rem;
    line-height: .48rem;
    span{
      color: #1675FF;
      margin-bottom: .3rem;
      display: inline-block;
    }
    .refresh{
      width: 100%;
      line-height: .6rem;
      text-align: center;
      img{
        width: .24rem;
        height: .24rem;
        display: inline-block;
        vertical-align: middle;
        margin-right: .16rem;
      }
  }
  .tab-div{
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 2;
    overflow: hidden;
    margin-bottom: .1rem;
    border-bottom: 1px solid  rgba(151, 151, 151, .1);
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    div{
      margin-top: .1rem;
      padding-bottom: .12rem;
    }
    .arrow{
      align-self: center;
    }
  }
  :last-child{
    border: none;
  }
}

</style>
